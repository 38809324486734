footer {
    background-color: #1C2672;
    color: white;
    position: relative;
}

footer .footer-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    bottom: 0;
}

.footer {
    padding: 68px 121px;
    display: flex;
    position: relative;
    z-index: 10;
    gap: 78px;
    flex-direction: row;
    justify-content: space-around;
}

.footer ul li {
    list-style: none;
}

.apdownload {
    display: flex;

    flex-direction: column;
}

.apdownload img {
    margin-top: 20px;
}

.fixed-bg {
    background-color: #414B97;
    padding: 16px 7px;
    border-radius: 30px;
    display: flex;
    gap: 10px;
    align-items: center;
    flex-direction: column;
}

.fixed-button {
    position: fixed;
    display: flex;
    right: 50px;
    bottom: 50px;
    z-index: 100;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.fixed-button button {
    border: none;
    background-color: transparent;
}

.foot1 p {
    color: #FFF;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    /* font-size: 14px; */
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.14px;
}

.footer-title {
    color: #FFF;
    text-align: justify;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    text-transform: uppercase;
    margin-bottom: 26px;
}

.footer-list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.footer-list li a {
    color: #FFF;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.14px;
    text-decoration: none;
}

.bottom-footer {
    height: 70px;
    background-color: #10193B;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.bottom-footer p {
    font-size: 11px;
    font-weight: 400;
    color: rgba(140, 148, 163, 1);
    font-family: "Inter", sans-serif;
}

/*   
  .down-arrow { position: absolute; right: 50px;
    top: 280px;
    cursor: pointer;
    z-index: 10;} */
/* .down-arrow { position: absolute; right: 20px;
      top: unset; bottom: 110px;
      cursor: pointer;
      z-index: 10;} */
@media(max-width: 480px) {

    .footer {
        padding: 50px 20px;
        gap: 0px;
        flex-direction: column;
    }

    .foot3 {
        display: flex;
        flex-direction: column;
        gap: 0;
        justify-content: unset;
        margin-top: 20px;
        margin-bottom: 30px;
    }

    .fixed-button {
        gap: 6px;
        bottom: 20px;
        right: 20px;
    }

    .franchise-title {
        text-align: center;
    }

    .fixed-button button img {
        height: 30px;
        width: 30px;
    }
}

/* .result-img {
    height: 281px;
    width: 271px;
    object-fit: cover;
    border-radius: 10px;
  } */
/*   
  .bottom-footer p span {
    color: rgba(255, 255, 255, 1);
  }

  
.down-arrow { position: absolute; right: 50px;
    top: 280px;
    cursor: pointer;
    z-index: 10;}
    @media(max-width: 480px) {
      .down-arrow { position: absolute; right: 20px;
        top: unset; bottom: 110px;
        cursor: pointer;
        z-index: 10;}
    } */