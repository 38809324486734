.navbar-item { z-index: 100;  transition: ease 0.5s;}
.overlay { display: none; background-color: rgba(0, 0, 0, 0.66); position: fixed; top: 0; left: 0; bottom: 0; z-index: 80; transition: ease 0.5s; }

@media(max-width: 768px) {
    .overlay { display: block;}
}

.link:hover .section-dropdown { display: block;}
 
.section-dropdown {
    position: absolute;
    padding: 5px;
    background-color: #fff;
    top: 50px;
    left: 0;
    width: 180px; 
    border-radius: 4px;
    display: block;
    box-shadow: 0 14px 35px 0 rgba(9, 9, 12, 0.4);
    z-index: 222;
    /* opacity: 0; */
    pointer-events: none;
    transform: translateY(20px);
    transition: all 200ms linear;
  }
  .sec-center .section-dropdown {
      background-color: #fff;
      box-shadow: 0 14px 35px 0 rgba(9,9,12,0.15);
    }
    .section-dropdown{
      /* opacity: 1; */
      pointer-events: auto;
      transform: translateY(0);
    }
    .section-dropdown:before {
      position: absolute;
      top: -20px;
      left: 0;
      width: 100%;
      height: 20px;
      content: '';
      display: block;
      z-index: 1;
    }
    .section-dropdown:after {
      position: absolute;
      top: -7px;
      left: 30px;
      width: 0; 
      height: 0; 
      border-left: 8px solid transparent;
      border-right: 8px solid transparent; 
      border-bottom: 8px solid #111;
      content: '';
      display: block;
      z-index: 2;
      transition: all 200ms linear;
    }
    /* .dark-light:checked ~ .sec-center .section-dropdown:after {
      border-bottom: 8px solid #fff;
    } */
    
    .section-dropdown  a {
      position: relative;
      color: #fff;
      transition: all 200ms linear;
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      font-size: 15px;
      border-radius: 2px;
      padding: 5px 0;
      padding-left: 20px;
      padding-right: 15px;
      margin: 2px 0;
      text-align: left;
      text-decoration: none;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      justify-content: space-between;
        -ms-flex-pack: distribute;
    }
    .dark-light:checked ~ .sec-center .section-dropdown a {
      text-decoration: none; 
      color: #000;
    }
    .section-dropdown a:hover {
      text-decoration: none;
      color: #FFF !important;
      background-color: #e31e24;
    }
.section-dropdown a { text-decoration: none; color: #000;}



@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  animation: spin 3s linear infinite;
}


